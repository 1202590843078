import React from "react";
import AppRouter from "./routes/index";

const App = () => {
  return (
    <AppRouter />
  );
};

export default App;
